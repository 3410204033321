@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box ;
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar{
    width: 0.6rem;
}

::-webkit-scrollbar-thumb{
    background-color: #D5D8DC;
    border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb:hover{
    background-color: #566573 ;

}

::-webkit-scrollbar-thumb:active{
    background-color: #273746;
}

/* slider de publicidad*/

.Carrusel {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    height: 60vh;
    width: 50vh;
    background: black;
  }
  
  .seperator {
    width: 100%;
  }
  
  .controls-wrapper {
    width: 100%;
  }
  
  .carousel-wrapper {
    width: 100%;
    background: white;
    color: black;
  }
  
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px red;
  }

  .active {
    transition: all 200ms ease-in-out;
    background-color: green;
    font-size: 7em;
  }
  