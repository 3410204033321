@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box ;
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar{
    width: 0.6rem;
}

::-webkit-scrollbar-thumb{
    background-color: #D5D8DC;
    border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb:hover{
    background-color: #566573 ;

}

::-webkit-scrollbar-thumb:active{
    background-color: #273746;
}

/* slider de publicidad*/

.Carrusel {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    height: 60vh;
    width: 50vh;
    background: black;
  }
  
  .seperator {
    width: 100%;
  }
  
  .controls-wrapper {
    width: 100%;
  }
  
  .carousel-wrapper {
    width: 100%;
    background: white;
    color: black;
  }
  
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px red;
  }

  .active {
    transition: all 200ms ease-in-out;
    background-color: green;
    font-size: 7em;
  }
  
.cover-container {
    height: 100vh;
    width: 100%;
    object-fit: contain;  
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;

}

.imagen {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.logoRadio:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: 1s;
}

.cover-container > h1 {
    color: white;
    font-size: 100px;
}

.cover-container > h3 {
    color: white;
    font-size: 40px;
    margin-top: 30px;
}

.cover-container > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
}

.logoRadio{
    object-fit: cover;
    width: 60%;
}

.contenedor-efecto{
    width: 600px;
    height:60px;
    color: #fff;
    font-size: 70px;
    line-height:68px;
    padding: 10px;

    position: absolute;
    top: 62vh;
    right: -20vh;
    bottom: 0;
    left: 0;
    margin: auto;

    overflow: hidden;
}

.contacto-text{
    margin-top: 8px;
    margin-left: -150vh;
    color: white;
    font-size: 32px;
    background: transparent;
}


.cover-texto{
    margin-left: -95vh;
    font-size: 80px !important;
    
}

@media screen and (max-width: 960px){
    .cover-container > h1{
        font-size: 70px;
        text-align: center;
    }

    .cover-container > p {
        text-align: center;
        font-size: 22px;
        width: 85%;
    }

    .logoRadio{
        object-fit: cover;
        width: 80%;
    }

}







.navbar{
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    height: 80px;
    padding-left: 60px;
    font-size: 20px;
    z-index: 1;
}

.scrolling {
    background-color: #5DADE2 ;
    transition: all 1s ease;
}

.navbar-logo{
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    position: absolute;
    margin-top: 1px;
    
    
}

.player{
    width: 40px;
    position: relative;
    background-color: transparent !important;
    margin-left: 100vh;
    

}

@media only screen and (max-width: 960px){
    .navbar{
        width: 100%;
        margin: 0 auto;
        padding: 40px 10px 50px 20px;
        font-size: 15px;
    }

    .player{
        width: 80% !important;
        margin: -38px;
        margin-left: 10vh;
        padding: 100px 20px 50px 20px;
    }

}


.about-container {
    display: flex;
    background-color: white;
    color: black;
    height: 70vh;
    padding: 100px 0 100px 0;
}

.about-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 0px 100px 0 100px;

}

.about-desc > h3 {
    margin-bottom: 20px;
    font-size: 28px;
    color: black;

}

.about-desc > p {
    line-height: 1.5 ;
    margin: 40px;
}

.about-img {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-right: 20vh !important;
    padding: 20px;
    
    
}

.about-img2 {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-right: 20vh !important;
    padding: 20px;
    
    
}

.titulo-about{
    border: 2px solid black;
    border-radius: 20px;
    background: black;
    padding: 8px;
    
    color: white !important;
}

@media only screen and (max-width: 960px){
    .about-container{
        flex-direction: column;
        padding: 0;
        height: auto;
    }

    .about-desc{
        width: 90%;
        margin: 0 auto;
        padding: 40px 20px 50px 20px;
    }

    .about-desc > h3{
        font-size: 20px;
        text-align: center;
    }

    .about-img{
        width: 100%;
        align-items: grid;
        margin-left: 40px;
        margin-bottom: 50px;
        margin-left: 50vh;
        grid-template-columns: 2fr;
    }

    .about-img > img{
        width: 100%;
    }

    .about-img2{
        width: 80%;
        align-items: grid;
        margin-left: 40px;
        margin-bottom: 50px;
        margin-left: 50vh;
        grid-template-columns: 2fr;
    }

    .about-img2 > img{
        width: 80%;
    }
}
.imagenes-container {
    display: flex;
    vertical-align: center;
    align-items: center;
    background-color: white;
    padding: 30px 0 30px 0;
    overflow: auto;
    height: 50vh;
    

}


.imagen-galeria{
    padding: 35px;
    width: 22vh;
    border-radius: 60px;
}



.galeria-titulo{
    border: 2px solid black;
    border-radius: 40px;
    padding: 10px;
    background: black;
    color: white;
}

.imagen-galeria:hover {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    transition: 1s;
    
}



@media only screen and (max-width: 960px){
    .imagenes-container{
        flex-direction: column;
        padding: 50px 0 50px 0;
        width: 100%;
        height: 150%;
        grid-template-columns: 2fr;
        overflow: hidden;

    }

   

    .imagen-galeria{
        width: 60%;
        grid-template-columns: 2fr;
    }
}


.footer {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    padding-top: 180px;
    position: fixed;
    z-index: -2;
    bottom: 0;
    left: 0;
}

.footer-info{
    font-size: 35px;
    padding: 30px 0 30px 50px;
    width: 70%;
    margin: 0 auto;
}


.footer-info > h1 {
    padding-bottom: 15px;
}

.footer-contact {
    font-size: 22px;
    padding: 100px 40px 30px 50px;
    width: 70%;
    margin: 0 auto;
    
}

.footer-contact2 {
    font-size: 22px;
    padding: 100px 40px 30px 50px;
    width: 70%;
    margin: 0 auto;
    
}

.footer-sns {
    width: 70%;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
}

.sns-links{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.fa-android{
    color: #00bb2d;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.facebook{
    color: #3B5998;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.youtube{
    color: #c4302b;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.fa-whatsapp{
    color: #00bb2d;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.youtube:hover{

    border-bottom: 4px solid white;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 1s;
}

.fa-android:hover{
    border-bottom: 4px solid white;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 1s;
}

.facebook:hover{
    border-bottom: 4px solid white;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 1s;
}

.fa-whatsapp:hover{
    border-bottom: 4px solid white;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 1s;
}


.design-by{
    font-size: 18px;
    padding-left: 50px;
    color: white;
}

@media only screen and  (max-width:960px){
    .footer-info{
        text-align: center;
        padding: 0;
        font-size: 20px;
    }

    .footer-contact{
        font-size: 16px;
        padding: 100px 0 30px 0;
        text-align: center;
    }

    .footer-contact2{
        font-size: 16px;
        padding: 80px 20px 30px 0;
        text-align: center;
    }

    .footer-sns{
        grid-template-columns: 1fr;
        text-align: center;
    }

    .design-by{
        padding: -30px 0 50px 0;
    }


}

.programacion-container {
    display: flex;
    background-color: black;
    color: white;
    height: 70vh;
    padding: 100px 0 100px 0;
}

.programacion-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 0px 50px 0 100px;

}

.programacion-desc > h3 {
    margin-bottom: 20px;
    font-size: 28px;
    

}

.programacion-desc > p {
    line-height: 1 ;
    margin: 20px;
}

.programacion-img {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-left: 20vh;
       
    
    
}

h4{
    color: #3498DB ;
    border: 1px solid white;
    display: inline-block;
    border-radius: 100px;
    padding: 5px;
    font-size: 16px;
}

.programacion-text{
    border: 2px solid white;
    background: #5DADE2;    
    color: black;
    border-radius: 20px;
    padding: 5px;
    z-index: 1;
    font-size: 25px;
    
}

h4:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 1;
    transition: 0.5s;
    background-color:#1C2833;
    cursor: pointer;
}

.programacion-img:hover{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    transition: 0.8s;
    cursor: pointer;
    
}

.programacion-img{
    border-radius: 20px;
}

@media only screen and (max-width: 960px){
    .programacion-container{
        flex-direction: column;
        padding: 0;
        height: auto;
    }

    .programacion-desc{
        width: 90%;
        margin: 0 auto;
        padding: 40px 20px 50px 20px;
    }

    .programacion-img:hover{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: 0.8s;
        cursor: pointer;
        z-index: 0;
    }

    

    .programacion-desc > h3{
        font-size: 20px;
        text-align: center;
    }

    

    .about-img > img{
        width: 100%;
    }
}


.contador{
    
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: black;
}



.contador-container{
    width: 100%;
    height: 50px;
    background-color: black;
    color: white;
    align-items: center;
    margin-bottom: 100vh;
    font-size: 20px;
}
