.navbar{
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    height: 80px;
    padding-left: 60px;
    font-size: 20px;
    z-index: 1;
}

.scrolling {
    background-color: #5DADE2 ;
    transition: all 1s ease;
}

.navbar-logo{
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    position: absolute;
    margin-top: 1px;
    
    
}

.player{
    width: 40px;
    position: relative;
    background-color: transparent !important;
    margin-left: 100vh;
    

}

@media only screen and (max-width: 960px){
    .navbar{
        width: 100%;
        margin: 0 auto;
        padding: 40px 10px 50px 20px;
        font-size: 15px;
    }

    .player{
        width: 80% !important;
        margin: -38px;
        margin-left: 10vh;
        padding: 100px 20px 50px 20px;
    }

}

