.contador{
    
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: black;
}



.contador-container{
    width: 100%;
    height: 50px;
    background-color: black;
    color: white;
    align-items: center;
    margin-bottom: 100vh;
    font-size: 20px;
}