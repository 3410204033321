.programacion-container {
    display: flex;
    background-color: black;
    color: white;
    height: 70vh;
    padding: 100px 0 100px 0;
}

.programacion-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 0px 50px 0 100px;

}

.programacion-desc > h3 {
    margin-bottom: 20px;
    font-size: 28px;
    

}

.programacion-desc > p {
    line-height: 1 ;
    margin: 20px;
}

.programacion-img {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-left: 20vh;
       
    
    
}

h4{
    color: #3498DB ;
    border: 1px solid white;
    display: inline-block;
    border-radius: 100px;
    padding: 5px;
    font-size: 16px;
}

.programacion-text{
    border: 2px solid white;
    background: #5DADE2;    
    color: black;
    border-radius: 20px;
    padding: 5px;
    z-index: 1;
    font-size: 25px;
    
}

h4:hover {
    transform: scale(1.05);
    opacity: 1;
    transition: 0.5s;
    background-color:#1C2833;
    cursor: pointer;
}

.programacion-img:hover{
    transform: scale(1.5);
    transition: 0.8s;
    cursor: pointer;
    
}

.programacion-img{
    border-radius: 20px;
}

@media only screen and (max-width: 960px){
    .programacion-container{
        flex-direction: column;
        padding: 0;
        height: auto;
    }

    .programacion-desc{
        width: 90%;
        margin: 0 auto;
        padding: 40px 20px 50px 20px;
    }

    .programacion-img:hover{
        transform: scale(1.2);
        transition: 0.8s;
        cursor: pointer;
        z-index: 0;
    }

    

    .programacion-desc > h3{
        font-size: 20px;
        text-align: center;
    }

    

    .about-img > img{
        width: 100%;
    }
}

