.footer {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    padding-top: 180px;
    position: fixed;
    z-index: -2;
    bottom: 0;
    left: 0;
}

.footer-info{
    font-size: 35px;
    padding: 30px 0 30px 50px;
    width: 70%;
    margin: 0 auto;
}


.footer-info > h1 {
    padding-bottom: 15px;
}

.footer-contact {
    font-size: 22px;
    padding: 100px 40px 30px 50px;
    width: 70%;
    margin: 0 auto;
    
}

.footer-contact2 {
    font-size: 22px;
    padding: 100px 40px 30px 50px;
    width: 70%;
    margin: 0 auto;
    
}

.footer-sns {
    width: 70%;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
}

.sns-links{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.fa-android{
    color: #00bb2d;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.facebook{
    color: #3B5998;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.youtube{
    color: #c4302b;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.fa-whatsapp{
    color: #00bb2d;
    font-size: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
}

.youtube:hover{

    border-bottom: 4px solid white;
    transform: scale(1.3);
    transition: 1s;
}

.fa-android:hover{
    border-bottom: 4px solid white;
    transform: scale(1.3);
    transition: 1s;
}

.facebook:hover{
    border-bottom: 4px solid white;
    transform: scale(1.3);
    transition: 1s;
}

.fa-whatsapp:hover{
    border-bottom: 4px solid white;
    transform: scale(1.3);
    transition: 1s;
}


.design-by{
    font-size: 18px;
    padding-left: 50px;
    color: white;
}

@media only screen and  (max-width:960px){
    .footer-info{
        text-align: center;
        padding: 0;
        font-size: 20px;
    }

    .footer-contact{
        font-size: 16px;
        padding: 100px 0 30px 0;
        text-align: center;
    }

    .footer-contact2{
        font-size: 16px;
        padding: 80px 20px 30px 0;
        text-align: center;
    }

    .footer-sns{
        grid-template-columns: 1fr;
        text-align: center;
    }

    .design-by{
        padding: -30px 0 50px 0;
    }


}
