.about-container {
    display: flex;
    background-color: white;
    color: black;
    height: 70vh;
    padding: 100px 0 100px 0;
}

.about-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 0px 100px 0 100px;

}

.about-desc > h3 {
    margin-bottom: 20px;
    font-size: 28px;
    color: black;

}

.about-desc > p {
    line-height: 1.5 ;
    margin: 40px;
}

.about-img {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-right: 20vh !important;
    padding: 20px;
    
    
}

.about-img2 {
    height: 100%;
    display: flex;
    align-self: flex-end;
    overflow: hidden;
    padding-right: 20vh !important;
    padding: 20px;
    
    
}

.titulo-about{
    border: 2px solid black;
    border-radius: 20px;
    background: black;
    padding: 8px;
    
    color: white !important;
}

@media only screen and (max-width: 960px){
    .about-container{
        flex-direction: column;
        padding: 0;
        height: auto;
    }

    .about-desc{
        width: 90%;
        margin: 0 auto;
        padding: 40px 20px 50px 20px;
    }

    .about-desc > h3{
        font-size: 20px;
        text-align: center;
    }

    .about-img{
        width: 100%;
        align-items: grid;
        margin-left: 40px;
        margin-bottom: 50px;
        margin-left: 50vh;
        grid-template-columns: 2fr;
    }

    .about-img > img{
        width: 100%;
    }

    .about-img2{
        width: 80%;
        align-items: grid;
        margin-left: 40px;
        margin-bottom: 50px;
        margin-left: 50vh;
        grid-template-columns: 2fr;
    }

    .about-img2 > img{
        width: 80%;
    }
}