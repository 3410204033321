.cover-container {
    height: 100vh;
    width: 100%;
    object-fit: contain;  
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;

}

.imagen {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.logoRadio:hover{
    transform: scale(1.2);
    transition: 1s;
}

.cover-container > h1 {
    color: white;
    font-size: 100px;
}

.cover-container > h3 {
    color: white;
    font-size: 40px;
    margin-top: 30px;
}

.cover-container > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
}

.logoRadio{
    object-fit: cover;
    width: 60%;
}

.contenedor-efecto{
    width: 600px;
    height:60px;
    color: #fff;
    font-size: 70px;
    line-height:68px;
    padding: 10px;

    position: absolute;
    top: 62vh;
    right: -20vh;
    bottom: 0;
    left: 0;
    margin: auto;

    overflow: hidden;
}

.contacto-text{
    margin-top: 8px;
    margin-left: -150vh;
    color: white;
    font-size: 32px;
    background: transparent;
}


.cover-texto{
    margin-left: -95vh;
    font-size: 80px !important;
    
}

@media screen and (max-width: 960px){
    .cover-container > h1{
        font-size: 70px;
        text-align: center;
    }

    .cover-container > p {
        text-align: center;
        font-size: 22px;
        width: 85%;
    }

    .logoRadio{
        object-fit: cover;
        width: 80%;
    }

}






