.imagenes-container {
    display: flex;
    vertical-align: center;
    align-items: center;
    background-color: white;
    padding: 30px 0 30px 0;
    overflow: auto;
    height: 50vh;
    

}


.imagen-galeria{
    padding: 35px;
    width: 22vh;
    border-radius: 60px;
}



.galeria-titulo{
    border: 2px solid black;
    border-radius: 40px;
    padding: 10px;
    background: black;
    color: white;
}

.imagen-galeria:hover {
    transform: scale(1.8);
    transition: 1s;
    
}



@media only screen and (max-width: 960px){
    .imagenes-container{
        flex-direction: column;
        padding: 50px 0 50px 0;
        width: 100%;
        height: 150%;
        grid-template-columns: 2fr;
        overflow: hidden;

    }

   

    .imagen-galeria{
        width: 60%;
        grid-template-columns: 2fr;
    }
}

